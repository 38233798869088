<template>
  <div class="register">

    <!-- register_annual_subheader -->
    <section id="sub_header_box" class="annual">
      <div class="w1300">

        <div class="sub_header_description txt-white page_header_space">
          <h3 class="sub_header_description_title">台灣居家醫療醫學會2020年學術研討會暨第二屆年會</h3>
          <p>今年主題著重於居家安寧病人照護實務經驗。</p>
          <p>藉由實務經驗分享，互相學習交流，讓居家醫療更能符合患者需求。</p>
        </div>

        <img class="heart_bg" src="@/statics/img/about/bg_heart.png" alt="">

      </div>
    </section>

    <PageSubHeader :pageSubHeader="pageSubHeader" />

    <section id="annual_member">
      <div class="w1300 layout_container">

        <div class="main_title_box">
          <h3 class="main_title txt-bold">會員報名</h3>
          <div class="img_box">
            <img src="@/statics/img/index/icon_title2.svg" alt="">
          </div>
        </div>

        <!-- form area -->
        <div class="form_box">
          <form
            id="form_annual_memberTrue_signup"
            action="register_annual_memberTrue_success.php"
            method="post"
            onsubmit="return validateForm();"
          >

            <!-- 表單互動區 -->
            <div class="form_interactive">
              <div class="half">
                <div class="fixed_info w50">
                  <p class="fixed_info_title">姓名</p>
                  <p class="fixed_info_content">王小明</p>
                </div>
                <div class="fixed_info w50">
                  <p class="fixed_info_title">會員編號</p>
                  <p class="fixed_info_content">009</p>
                </div>
              </div>
              <div class="half">
                <div class="fixed_info w100">
                  <p class="fixed_info_title">出生年月日</p>
                  <p class="fixed_info_content">1990-01-10</p>
                </div>
              </div>
              <div class="fixed_info w50">
                <p class="fixed_info_title">身分證字號</p>
                <p class="fixed_info_content">A012345678</p>
              </div>
              <label for="mobile" class="w50">
                <p><span class="txt-red txt-regular">*</span>手機號碼</p>
                <input
                  type="text"
                  id="mobile"
                  name="mobile"
                  placeholder="請輸入手機號碼"
                  value="0987654321"
                  required
                >
              </label>
              <label for="email" class="w50">
                <p><span class="txt-red txt-regular">*</span>Email</p>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value="abcd1234@gmail.com"
                  placeholder="請輸入完整Email"
                  required
                >
              </label>
              <label for="hospital" class="w50">
                <p>
                  <span class="txt-red txt-regular">*</span>
                  院所名稱 <span class="txt-regular">(例如：台灣居家醫療醫學會)</span>
                </p>
                <input
                  type="text"
                  id="hospital"
                  name="hospital"
                  value="真心中醫"
                  placeholder="請輸入完整名稱"
                  required
                >
              </label>
              <div class="select_container w100">
                <p><span class="txt-red txt-regular">*</span>院所地址</p>
                <div class="select_container-left w50">
                  <select
                    name="city"
                    id="city"
                    class="select_city w33"
                    data-area_col="area"
                    data-postid_col="postid"
                    required
                  >
                      <option value="">縣市</option>
                      <option value="taipei" selected>台北市</option>
                  </select>
                  <select
                    name="area"
                    id="area"
                    class="select_area w33"
                    data-postid_col="postid"
                    data-city=""
                    required
                  >
                      <option value="">鄉鎮市區</option>
                      <option value="daan" selected>大安區</option>
                  </select>
                  <input
                    type="text"
                    name="postid"
                    readonly
                    id="postid"
                    value=""
                    class="w33"
                    placeholder="郵遞區號"
                    required
                  >
                </div>
                <div class="select_container-right w50">
                  <input
                    type="text"
                    name="addr"
                    id="addr"
                    value="仁愛路123號"
                    required
                    placeholder="請填寫戶籍地址"
                  >
                </div>
              </div>
              <label for="title" class="w100">
                <p><span class="txt-red star_hidden">*</span>收據抬頭</p>
                <input type="text" id="title" name="title" placeholder="請輸入抬頭" value="">
              </label>
              <div class="radio_container w50">
                <p><span class="txt-red txt-regular">*</span>身分</p>
                <label class="p">
                  <input
                    type="radio"
                    name="user_type"
                    value="醫師"
                    class="readonly"
                    checked
                    required
                    onclick="return false"
                  >醫師
                  <div class="checkmark"></div>
                </label>
                <label class="p">
                  <input
                    type="radio"
                    name="user_type"
                    value="護理人員"
                    class="readonly"
                    required
                    onclick="return false"
                  >護理人員
                  <div class="checkmark"></div>
                </label>
              </div>
              <div class="radio_container w50">
                <p><span class="txt-red txt-regular">*</span>飲食習慣</p>
                <label class="p">
                  <input type="radio" name="eating_habits" value="葷食" required>葷食
                  <div class="checkmark"></div>
                </label>
                <label class="p">
                  <input type="radio" name="eating_habits" value="素食" required>素食
                  <div class="checkmark"></div>
                </label>
              </div>
              <div class="checkbox_container required">
                <p>
                  <span class="txt-red txt-regular">*</span>
                  請選擇積分 <span class="txt-regular">(可複選)</span>
                </p>
                <label for="medical">
                  <p class="checkbox_item_name">醫事人員積分</p>
                  <input id="medical" name="point_category[]" type="checkbox" value="medical">
                  <span class="checkmark"></span>
                </label>
                <label for="nursing">
                  <p class="checkbox_item_name">護理人員積分</p>
                  <input id="nursing" name="point_category[]" type="checkbox" value="nursing">
                  <span class="checkmark"></span>
                </label>
                <label for="longTerm">
                  <p class="checkbox_item_name">長照服務人員積分</p>
                  <input id="longTerm" name="point_category[]" type="checkbox" value="longTerm">
                  <span class="checkmark"></span>
                </label>
                <label for="ourA">
                  <p class="checkbox_item_name">居家醫療積分A類</p>
                  <input id="ourA" name="point_category[]" type="checkbox" value="ourA">
                  <span class="checkmark"></span>
                </label>
                <label for="ourB">
                  <p class="checkbox_item_name">居家醫療積分B類</p>
                  <input id="ourB" name="point_category[]" type="checkbox" value="ourB">
                  <span class="checkmark"></span>
                </label>
                <label for="none">
                  <p class="checkbox_item_name">不申請積分</p>
                  <input id="none" name="point_category[]" type="checkbox" value="none">
                  <span class="checkmark"></span>
                </label>
              </div>
            </div>

            <div class="form_submit txt-center">
              <router-link to="/register/annual/success" class="btn p">
                下一步
              </router-link>
              <!-- <button class="btn p">下一步</button> -->
            </div>

          </form>
        </div>

      </div>
    </section>
  </div>
</template>

<script>
import PageSubHeader from '@/components/PageSubHeader.vue';
import { RegisterAnnualSubHeader } from '@/lib/const';

export default {
  name: 'RegisterAnnualMember',
  data() {
    return {
      ...RegisterAnnualSubHeader,
    };
  },
  components: {
    PageSubHeader,
  },
};
</script>
